import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { FC } from 'react'

import { useFavoritesSidebarStyles } from './favorites-sidebar.styles'
import { useLocale, useTranslation } from '@plandi/common'

type Props = {
  favoriteProjectsCount?: number
  favoriteModelsCount?: number
  favoritesGoodsCount?: number
  handleChangeFavoritesSubtype: (type: string) => void
}

export const ShopFavoritesSidebar: FC<Props> = ({
  favoriteProjectsCount,
  favoriteModelsCount,
  favoritesGoodsCount,
  handleChangeFavoritesSubtype
}) => {
  const classes = useFavoritesSidebarStyles()
  const router = useRouter()
  const { data: translate } = useTranslation()
  const locale = useLocale()

  return (
    <div className={classes.root}>
      <button
        onClick={() => handleChangeFavoritesSubtype('models')}
        className={clsx(classes.sidebarBtn, {
          [classes.activeBtn]:
            router.query.type === 'models' || !router.query.type
        })}
      >
        <span className={classes.btnText}>
          {translate?.favorites?.['bim-models']}
        </span>
        <span>{favoriteModelsCount || 0}</span>
      </button>
      <button
        onClick={() => handleChangeFavoritesSubtype('projects')}
        className={clsx(classes.sidebarBtn, {
          [classes.activeBtn]: router.query.type === 'projects'
        })}
      >
        <span className={classes.btnText}>
          {translate?.favorites?.['projects']}
        </span>
        <span>{favoriteProjectsCount || 0}</span>
      </button>
      <button
        onClick={() => handleChangeFavoritesSubtype('goods')}
        className={clsx(classes.sidebarBtn, {
          [classes.activeBtn]: router.query.type === 'goods'
        })}
      >
        <span className={classes.btnText}>
          {locale === 'en' ? 'Goods' : 'Товары'}
        </span>
        <span>{favoritesGoodsCount || 0}</span>
      </button>
    </div>
  )
}
