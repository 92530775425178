export const URLS = {
  home: '/applicants',
  employers: '/employers',
  applicants: '/applicants',
  vacancyInfo: '/vacancy-info',
  messages: '/messages',
  notifications: '/notifications',
  favorites: '/favorites',
  profile: '/profile',
  advert: {
    create: '/tenders/create',
    me: '/tenders/me',
    feedbacks: '/tenders/feedbacks',
    company: {
      detailTender: '/tenders/company/[slugName]/[tenderId]',
      editTender: '/tenders/company/[tenderId]/edit',
      feedbacksTender: '/tenders/company/[tenderId]/feedbacks',
      chatFeedbackOwner:
        '/tenders/company/[tenderId]/feedbacks/[feedbackId]/chat/[profileId]',
      chatFeedbackApplicant:
        '/tenders/feedbacks/[feedbackId]/company/[profileId]/chat'
    },
    specialist: {
      detailTender: '/tenders/specialist/[slugName]/[tenderId]',
      editTender: '/tenders/specialist/[tenderId]/edit',
      feedbacksTender: '/tenders/specialist/[tenderId]/feedbacks',
      chatFeedbackOwner:
        '/tenders/specialist/[tenderId]/feedbacks/[feedbackId]/chat/[profileId]',
      chatFeedbackApplicant:
        '/tenders/feedbacks/[feedbackId]/specialist/[profileId]/chat'
    }
  },
  profileSpecialist: {
    me: '/profile-specialist',
    create: '/profile-specialist/create',
    portfolio: '/profile-specialist/[profileId]/portfolio',
    project: '/profile-specialist/[profileId]/portfolio/[slug]',
    resume: '/profile-specialist/[profileId]',
    createProject: '/profile-specialist/[profileId]/portfolio/create',
    editProject: '/profile-specialist/[profileId]/portfolio/[slug]/edit'
  },
  profileCompany: {
    me: '/profile-company',
    create: '/profile-company/create',
    resume: '/profile-company/[profileId]',
    portfolio: '/profile-company/[profileId]/portfolio',
    project: '/profile-company/[profileId]/portfolio/[slug]',
    createProject: '/profile-company/[profileId]/portfolio/create',
    editProject: '/profile-company/[profileId]/portfolio/[slug]/edit'
  },
  notificationsSettings: '/notifications-settings'
} as const
