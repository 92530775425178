import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import clsx from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { FC, useRef, useState } from 'react'
import { ADVERT_TENDER_TYPE } from '../favorites.constants'

import { FAVORITES_TYPE, FAVORITES_TYPES } from '../types'
import { useFavoritesPopupMenuStyles } from './favorites-popup-menu.styles'
import { useTranslation } from '@plandi/common'

type FavoritesPopupMenuProps = {
  anchorEl?: Element
  favoritesType: FAVORITES_TYPE
  onItemClick: (value: string) => void
  count?: {
    models?: number
    projects?: number
    adverts?: number
    goods?: number
  }
}

export const FavoritesPopupMenu: FC<FavoritesPopupMenuProps> = ({
  favoritesType,
  onItemClick,
  count
}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const classes = useFavoritesPopupMenuStyles()
  const menuBtnRef = useRef(null)

  const router = useRouter()
  const type = router.query.type
  const { data: translate } = useTranslation()

  return (
    <div className={classes.root}>
      <IconButton
        innerRef={menuBtnRef}
        onClick={() => setIsMenuOpened((prev) => !prev)}
      >
        <Image src="/assets/filterIcon.svg" alt="" width={16} height={18} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={menuBtnRef.current}
        keepMounted
        open={isMenuOpened}
        onClose={() => setIsMenuOpened(false)}
        className={classes.root}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {favoritesType === FAVORITES_TYPES.ADVERTS ? (
          <div>
            <MenuItem
              className={classes.menuItem}
              onClick={() => onItemClick(ADVERT_TENDER_TYPE.ADVERTS)}
            >
              <Typography
                className={clsx(classes.menuItemText, {
                  [classes.activeItemText]: type !== ADVERT_TENDER_TYPE.COMPANY
                })}
              >
                {translate?.['total-tenders']}
              </Typography>
              <Typography
                className={clsx(classes.menuItemText, {
                  [classes.activeItemText]: type !== ADVERT_TENDER_TYPE.COMPANY
                })}
              >
                {count?.adverts}
              </Typography>
            </MenuItem>
          </div>
        ) : (
          <div>
            <MenuItem
              className={classes.menuItem}
              onClick={() => onItemClick('models')}
            >
              <Typography
                className={clsx(classes.menuItemText, {
                  [classes.activeItemText]: type === 'models'
                })}
              >
                {translate?.models}
              </Typography>
              <Typography
                className={clsx(classes.menuItemText, {
                  [classes.activeItemText]: type === 'models'
                })}
              >
                {count?.models}
              </Typography>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={() => onItemClick('projects')}
            >
              <Typography
                className={clsx(classes.menuItemText, {
                  [classes.activeItemText]: type === 'projects'
                })}
              >
                {translate?.projects}
              </Typography>
              <Typography
                className={clsx(classes.menuItemText, {
                  [classes.activeItemText]: type === 'projects'
                })}
              >
                {count?.projects}
              </Typography>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={() => onItemClick('goods')}
            >
              <Typography
                className={clsx(classes.menuItemText, {
                  [classes.activeItemText]: type === 'goods'
                })}
              >
                {translate?.market?.goods}
              </Typography>
              <Typography
                className={clsx(classes.menuItemText, {
                  [classes.activeItemText]: type === 'goods'
                })}
              >
                {count?.goods}
              </Typography>
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  )
}
