import { makeStyles, createStyles } from '@material-ui/core'

export const useModelCardStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingTop: 15,
      paddingBottom: 5,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
      [theme.breakpoints.down('xs')]: {
        padding: '15px 15px 18px 15px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)'
      },
      '&:hover $menu': {
        visibility: 'visible',
        transition: theme.transitions.create('visibility', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.complex
        })
      }
    },
    header_publication: {
      float: 'left',
      fontSize: 12
    },
    headerInfoIcons: {
      display: 'flex',
      gap: 26,
      alignItems: 'center'
    },
    favoritButton: {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        fontSize: 10,
        padding: 5
      }
    },
    favoritText: {
      marginTop: 4
    },
    favoritIcon: {
      color: 'white'
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    infoBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginTop: 25,
      paddingLeft: 15
    },
    infoBottomMobile: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 12,
      paddingRight: 20,
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    infoCard: {
      [theme.breakpoints.down('xs')]: {
        display: 'block'
      }
    },
    content: {
      padding: theme.spacing(1),
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
        padding: 0,
        alignItems: 'normal',
        width: '100%'
      }
    },

    name: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      overflow: 'inherit',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        height: 40
      }
    },
    iconName: {
      [theme.breakpoints.down('xs')]: {
        top: 10,
        left: 0
      }
    },
    fullName: {
      marginLeft: 12,
      marginTop: 2,
      color: 'black',
      overflow: 'hidden',
      lineHeight: '40px',
      [theme.breakpoints.down('xs')]: {
        textOverflow: 'ellipsis'
      }
    },
    img: {
      objectFit: 'contain',
      width: 275,
      height: 157,
      display: 'block',
      minHeight: 112,
      marginBottom: 9,
      [theme.breakpoints.down('xs')]: {
        width: 109,
        height: 112
      }
    },
    discription: {
      width: 260,
      fontSize: 14,
      textAlign: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        minHeight: 60.5
      }
    },
    iconWrap: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    projectName: {
      marginBottom: theme.spacing(1),
      display: 'block',
      fontSize: 18,
      textAlign: 'center',
      lineHeight: '20px',
      whiteSpace: 'break-spaces',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
        lineHeight: '20px',
        marginBottom: 4
      },
      [theme.breakpoints.up('sm')]: {
        height: 60
      }
    },
    projectNameSim: {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        lineHeight: '20px'
      }
    },
    actions: {
      alignItems: 'center'
    },
    cardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '0px 34px 0 14px',
      color: '#757575',
      [theme.breakpoints.down('xs')]: {
        padding: '0 0 10px 0'
      }
    },
    headerYeys: {
      display: 'flex',
      color: '#2E86BD',
      alignItems: 'center'
    },
    headerDate: {
      color: '#2E86BD',
      marginLeft: 5
    },
    viewdNumber: {
      marginLeft: 8,
      fontSize: 14
    },
    downloadCountNumber: {
      fontSize: 14,
      marginLeft: 8
    },
    menu: {
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center'
      }
    },
    favorit: {
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-start'
      }
    },
    mobileName: {
      display: 'flex',
      // padding: '0 20px',
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    mobileBlock: {
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    descriptionMobile: {
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    infoMobile: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 12
      }
    }
  })
)
