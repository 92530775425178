export const modelKeys = {
  all: ['models'] as const,
  favorites: () => [...modelKeys.all, 'favorites'] as const,
  favorite: (filters: Record<string, unknown> = {}) =>
    [...modelKeys.favorites(), filters] as const
}

export const projectKeys = {
  all: ['projects'] as const,
  favorites: () => [...projectKeys.all, 'favorites'] as const,
  favorite: (filters: Record<string, unknown> = {}) =>
    [...projectKeys.favorites(), filters] as const
}

export const specialistsAdvertsKeys = {
  all: ['specialistAdverts'] as const,
  favorites: () => [...specialistsAdvertsKeys.all, 'favorites'] as const,
  favorite: (filters: Record<string, unknown> = {}) =>
    [...specialistsAdvertsKeys.favorites(), filters] as const
}
export const companiesAdvertsKeys = {
  all: ['companiesAdverts'] as const,
  favorites: () => [...companiesAdvertsKeys.all, 'favorites'] as const,
  favorite: (filters: Record<string, unknown> = {}) =>
    [...companiesAdvertsKeys.favorites(), filters] as const
}
