import React, { useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import {
  ConfirmModal,
  SERVICES_URL,
  TranslationDictionary,
  useDisclosure
} from '@plandi/common'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Paper from '@material-ui/core/Paper'
import PublishIcon from '@material-ui/icons/Publish'
import ReportIcon from '@material-ui/icons/Report'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import { useProjectAuthorMenuStyles } from './project-author-menu.styles'
import {
  useMutationDeleteProject,
  useMutationDraftProject,
  useMutationPublishProject
} from '../mutations'

type ProjectAuthorMenuProps = {
  projectId?: number
  className?: string
  classNamePopper?: string
  deleteMutationOptions?: Parameters<typeof useMutationDeleteProject>[number]
  status?: 'draft' | 'published'
  translate?: TranslationDictionary['data']
}

export const ProjectAuthorMenu: React.FC<ProjectAuthorMenuProps> = (props) => {
  const classes = useProjectAuthorMenuStyles()
  const menuDisclosure = useDisclosure()
  const confirmDeleteProjectDisclosure = useDisclosure()
  const confirmPublishProjectDisclosure = useDisclosure()
  const confirmUnpublishProjectDisclosure = useDisclosure()
  const menuRef = useRef<HTMLButtonElement | null>(null)
  const router = useRouter()
  const { locale } = useRouter()

  const mutationDeleteProject = useMutationDeleteProject(
    props.deleteMutationOptions
  )

  const mutationPublishProject = useMutationPublishProject(props.translate)
  const mutationDraftProject = useMutationDraftProject()

  const handleChangeRouteEditProject = () => {
    if (props.projectId) {
      router.push({
        pathname:
          locale === 'ru'
            ? `${SERVICES_URL.shop}/ru${'/projects/[projectId]/edit'}`
            : SERVICES_URL.shop + '/projects/[projectId]/edit',
        query: {
          projectId: props.projectId
        }
      })
    }
  }

  const handleChangeRouteProjectChats = () => {
    if (props.projectId) {
      router.push({
        pathname:
          locale === 'ru'
            ? `${SERVICES_URL.shop}/ru${'/projects/[projectId]/chats'}`
            : SERVICES_URL.shop + '/projects/[projectId]/chats',
        query: {
          projectId: props.projectId
        }
      })
    }
  }

  const handleOpenConfirmDeleteProject = () => {
    menuDisclosure.onClose()

    confirmDeleteProjectDisclosure.onOpen()
  }

  const handleOpenConfirmPublishProject = () => {
    menuDisclosure.onClose()

    confirmPublishProjectDisclosure.onOpen()
  }

  const handleOpenConfirmUnpublishProject = () => {
    menuDisclosure.onClose()

    confirmUnpublishProjectDisclosure.onOpen()
  }

  const handleDeleteProject = () => {
    if (props.projectId) {
      confirmDeleteProjectDisclosure.onClose()

      mutationDeleteProject.mutate(props.projectId)
    }
  }

  const handleDraftProject = () => {
    if (props.projectId) {
      confirmUnpublishProjectDisclosure.onClose()

      mutationDraftProject.mutate(props.projectId)
    }
  }

  const handlePublishProject = () => {
    if (props.projectId) {
      confirmPublishProjectDisclosure.onClose()

      mutationPublishProject.mutate(props.projectId)
    }
  }

  return (
    <>
      <IconButton
        onClick={menuDisclosure.onOpen}
        ref={menuRef}
        className={props.className}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popper
        open={menuDisclosure.isOpen}
        anchorEl={menuRef.current}
        placement="bottom-end"
        className={clsx(classes.popper, props.classNamePopper)}
      >
        <Paper>
          <ClickAwayListener onClickAway={menuDisclosure.onClose}>
            <List>
              <ListItem button onClick={handleChangeRouteProjectChats}>
                <ListItemIcon>
                  <ChatBubbleIcon />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body2">
                    {props.translate?.menu.messages}
                  </Typography>
                </ListItemText>
              </ListItem>
              {props.status === 'draft' && (
                <ListItem button onClick={handleOpenConfirmPublishProject}>
                  <ListItemIcon>
                    <PublishIcon />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <Typography variant="body2">
                      {props.translate?.['to-publish']}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {props.status === 'published' && (
                <ListItem button onClick={handleOpenConfirmUnpublishProject}>
                  <ListItemIcon>
                    <ReportIcon />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <Typography variant="body2">
                      {
                        props.translate?.shop?.['my-models-page'][
                          'to-unpublish'
                        ]
                      }
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              <ListItem button onClick={handleChangeRouteEditProject}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body2">
                    {props.translate?.['edit-btn']}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem button onClick={handleOpenConfirmDeleteProject}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body2">
                    {props.translate?.['delete-btn']}
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </ClickAwayListener>
        </Paper>
      </Popper>
      {confirmDeleteProjectDisclosure.isOpen && (
        <ConfirmModal
          isOpen={confirmDeleteProjectDisclosure.isOpen}
          onClose={confirmDeleteProjectDisclosure.onClose}
          onOk={handleDeleteProject}
          title={props.translate?.['delete-project-confirmation']}
        />
      )}
      {confirmPublishProjectDisclosure.isOpen && (
        <ConfirmModal
          isOpen={confirmPublishProjectDisclosure.isOpen}
          onClose={confirmPublishProjectDisclosure.onClose}
          onOk={handlePublishProject}
          title={props.translate?.['publish-project-confirmation']}
        />
      )}
      {confirmUnpublishProjectDisclosure.isOpen && (
        <ConfirmModal
          isOpen={confirmUnpublishProjectDisclosure.isOpen}
          onClose={confirmUnpublishProjectDisclosure.onClose}
          onOk={handleDraftProject}
          title={`
          ${props.translate?.['unpublish-project-confirmation']}
           ${props.translate?.['activate-this-later']}
          `}
        />
      )}
    </>
  )
}
