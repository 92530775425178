import { AxiosError } from 'axios'
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query'
import {
  TranslationDictionary,
  pushSuccessNotification,
  useLocale
} from '@plandi/common'

import { useThunkDispatch } from 'src/store'
import { modelKeys } from '../favorites.keys'
import { favoritesApi } from '../favorites.api'

export const useMutationDeleteFavoriteModel = (
  _translate?: TranslationDictionary['data'],
  options?: UseMutationOptions<unknown, AxiosError, number>
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()

  const mutation = useMutation(
    (modelId) => favoritesApi.deleteFavoriteModel(modelId, locale),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? 'BIM-Модель успешно удалена из избранного'
              : 'BIM Model successfully removed from favorites'
          )
        )

        queryClient.invalidateQueries([modelKeys.all, locale])

        options?.onSuccess?.(data, variables, context)
      }
    }
  )

  return mutation
}
