import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import Link from 'next/link'
import Container from '@material-ui/core/Container'
import type { ContainerProps } from '@material-ui/core/Container'
import type { UrlObject } from 'url'

import { useEmptyContentStyles } from './empty-content.styles'

type EmptyContentProps = {
  message: string
  containerProps?: ContainerProps
} & (
  | {
      showButton?: never
      buttonProps?: never
    }
  | {
      showButton: boolean
      buttonProps: { href: string | UrlObject; text: string }
    }
)

export const EmptyContent = (props: EmptyContentProps) => {
  const classes = useEmptyContentStyles()

  return (
    <Container
      className={classes.container}
      maxWidth="md"
      {...props.containerProps}
    >
      <Typography variant="h5" gutterBottom align="center">
        {props.message}
      </Typography>
      {props.showButton && (
        <Link href={props.buttonProps?.href} passHref>
          <Button variant="contained" color="primary">
            {props.buttonProps?.text}
          </Button>
        </Link>
      )}
    </Container>
  )
}
