import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useAdvertTenderCardStyles = makeStyles((theme) =>
  createStyles({
    card: {
      width: '100%',
      padding: '24px 18px 24px 16px',
      marginBottom: 56,

      [theme.breakpoints.down('sm')]: {
        padding: '24px 16px'
      }
    },

    cardTop: {
      display: 'flex',
      flexDirection: 'column-reverse',
      marginBottom: 8,
      flexWrap: 'wrap',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between'
      }
    },

    cardTopLeft: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse'
    },

    cardTopRight: {
      display: 'flex',
      justifyContent: 'space-between'
    },

    mainTitle: {
      display: 'flex',
      flex: 1,
      marginTop: 13,
      cursor: 'pointer',
      order: 1,

      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        paddingRight: 16,
        order: 0
      }
    },

    nameInfo: {
      display: 'flex',
      alignItems: 'center'
    },

    verifyIcon: {
      marginLeft: 8
    },

    mainDesc: {
      marginTop: 8,
      marginBottom: 24
    },

    mainDescText: {
      [theme.breakpoints.down('sm')]: {
        display: '-webkit-box',
        '-webkit-line-clamp': 6,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },

    groupChip: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginBottom: 40,

      '& > div': {
        overflow: 'hidden',
        marginBottom: 8,
        marginRight: 16
      }
    },

    action: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'row-reverse'
      }
    },

    price: {
      fontWeight: 600
    },

    tooltip: {
      margin: 0
    },

    cardBottomLeft: {
      display: 'flex',
      alignItems: 'center'
    },

    cardStats: {
      margin: '0 62px 0 50px',
      display: 'flex',
      alignItems: 'center',
      gap: 10,

      [theme.breakpoints.down('xs')]: {
        margin: 0
      }
    },

    cardStatsBlock: {
      display: 'flex',
      alignItems: 'center',
      gap: 6,
      fontSize: 14,
      color: '#2E86BD'
    },

    cardStatsIcon: {
      width: 22,
      height: 16
    }
  })
)
