import { createStyles, makeStyles } from '@material-ui/core'

export const useSkeletonStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingTop: 15,
      paddingBottom: 31,
      [theme.breakpoints.down('xs')]: {
        padding: 15,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)'
      },
      '&:hover $menu': {
        visibility: 'visible',
        transition: theme.transitions.create('visibility', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.complex
        })
      }
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    content: {
      padding: theme.spacing(1),
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
        padding: 0,
        alignItems: 'normal',
        width: '100%'
      }
    },
    name: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      overflow: 'inherit',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        height: 40
      }
    },
    img: {
      objectFit: 'cover',
      width: 275,
      height: 157,
      display: 'block',
      minHeight: 112,
      marginBottom: 9,
      [theme.breakpoints.down('xs')]: {
        width: 109,
        height: 112
      }
    },
    projectName: {
      width: 200,
      marginBottom: theme.spacing(1),
      display: 'block',
      fontSize: 18,
      textAlign: 'center',
      maxHeight: 50,
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
        lineHeight: '20px'
      }
    }
  })
)
