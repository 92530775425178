import { createStyles, makeStyles } from '@material-ui/core'

export const useFavoritesSidebarStyles = makeStyles(() =>
  createStyles({
    root: {
      flex: 1,
      padding: '43px 40px 38px 32px',
      width: 315,
      maxHeight: 200,
      background: '#ffffff',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: 4
    },
    sidebarBtn: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 12,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      border: 'none',
      fontWeight: 500,
      fontFamily: 'Montserrat'
    },
    activeBtn: {
      color: '#e52428'
    },
    btnText: {
      fontSize: 16
    }
  })
)
