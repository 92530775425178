import React from 'react'
import { Masonry } from '@plandi/common'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

import { useProjectFavoritesStyles } from './project-favorites.styles'
import { ProjectCardSkeleton } from '../common'

export const ProjectFavoritesSkeleton = () => {
  const classes = useProjectFavoritesStyles()

  return (
    <>
      <div className={classes.head}>
        <div className={classes.titlegroup}>
          <Typography variant="h4" gutterBottom className={classes.title}>
            <Skeleton width={200} />
          </Typography>
          <Typography gutterBottom color="textSecondary">
            <Skeleton width={100} />
          </Typography>
        </div>
        <Skeleton className={classes.sort} />
      </div>
      <Masonry>
        {[...new Array(9)].map((_, i) => (
          <ProjectCardSkeleton key={i} />
        ))}
      </Masonry>
    </>
  )
}
