import React, { useState } from 'react'
import { TabContext, TabPanel } from '@material-ui/lab'
import { useRouter } from 'next/router'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'

import styles from './favorites-container.module.scss'
import {
  AdvertsFavoritesSidebar,
  FavoritesPopupMenu,
  FAVORITES_TYPE,
  FAVORITES_TYPES,
  ShopFavoritesSidebar,
  useQueryModelsFavorites,
  useQueryProjectsFavorites,
  getFavoritesTypesValue,
  useQueryGoodsFavorites
} from '../common'
import { ProjectFavorites } from '../project-favorites'
import { TabsComponent } from 'src/common'
import { ModelFavorites } from '../model-favorites'
import { AdvertFavorites } from '../adverts-favorites'
import { AdvertTenderType } from '../common/favorites.types'
import { BreadCrumbs } from 'src/features/breadcrumbs/breadcrumbs'
import { useQueryAdvertsFavorites } from '../common/queries/use-query-adverts-favorites '
import { useLocale, useTranslation } from '@plandi/common'
import { GoodsFavorites } from '../goods-favorites'

export const FavoritesContainer = () => {
  const router = useRouter()
  const type = router.query.type
  const isShop = type === 'models' || type === 'projects'
  const [favoritesType, setFavoritesType] = useState(
    isShop ? FAVORITES_TYPES.SHOP : FAVORITES_TYPES.ADVERTS
  )
  const { data: translate } = useTranslation()
  const locale = useLocale()

  const { data: projectsFavorites } = useQueryProjectsFavorites()
  const { data: modelsFavorites } = useQueryModelsFavorites()
  const { data: advertsFavorites } = useQueryAdvertsFavorites()
  const { data: goodsFavorites } = useQueryGoodsFavorites()

  const theme = useTheme()
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const onTabChange = (value: FAVORITES_TYPE | any) => {
    // const type = value === 'adverts' ? 'adverts' : 'models'
    setFavoritesType(value)
    // router.replace(
    //   {
    //     pathname: router.pathname,
    //     query: {
    //       type
    //     }
    //   },
    //   undefined,
    //   { shallow: true }
    // )
  }

  const handleChangeFavoritesSubtype = (value: string) => {
    router.replace(
      {
        // pathname: router.pathname,
        query: {
          ...router.query,
          type: value
        }
      },
      undefined,
      { shallow: true }
    )
  }

  const count = {
    models: modelsFavorites?.totalCount,
    projects: projectsFavorites?.totalCount,
    adverts: advertsFavorites?.total,
    goods: goodsFavorites?.totalCount
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerHeader}>
        <div className={styles.containerTop}>
          <BreadCrumbs
            breadcrumbInfo={[
              { title: translate?.favorites?.title ?? 'Favorites', href: '' }
            ]}
            styles={{ marginBottom: '30px' }}
          />
          {isTabletScreen && (
            <FavoritesPopupMenu
              favoritesType={favoritesType}
              onItemClick={handleChangeFavoritesSubtype}
              count={count}
            />
          )}
        </div>
        <Typography variant="h4" component="h1">
          {translate?.favorites?.title}
        </Typography>
      </div>
      <div className={styles.content}>
        <TabContext value={favoritesType}>
          <div className={styles.leftBlock}>
            <TabsComponent
              tabClassName={styles.tab}
              onTabChange={onTabChange}
              tabsValues={Object.entries(getFavoritesTypesValue(translate))}
            />
            <TabPanel value={FAVORITES_TYPES.SHOP} className={styles.tabPanel}>
              <ShopFavoritesSidebar
                favoriteProjectsCount={projectsFavorites?.totalCount}
                favoriteModelsCount={modelsFavorites?.totalCount}
                favoritesGoodsCount={goodsFavorites?.totalCount}
                handleChangeFavoritesSubtype={handleChangeFavoritesSubtype}
              />
            </TabPanel>
            <TabPanel
              value={FAVORITES_TYPES.ADVERTS}
              className={styles.tabPanel}
            >
              <AdvertsFavoritesSidebar
                advertsCount={advertsFavorites?.total}
                handleChangeFavoritesSubtype={handleChangeFavoritesSubtype}
              />
            </TabPanel>
          </div>
          <div className={styles.rightBlock}>
            <TabPanel
              className={styles.contentTabPanel}
              value={FAVORITES_TYPES.ADVERTS}
            >
              <AdvertFavorites
                onTabChange={onTabChange}
                type={type as AdvertTenderType}
              />
            </TabPanel>
            <TabPanel
              className={styles.contentTabPanel}
              value={FAVORITES_TYPES.SHOP}
            >
              {router.query.type === 'models' || !router.query.type ? (
                <ModelFavorites onTabChange={onTabChange} />
              ) : router.query.type === 'projects' ? (
                <ProjectFavorites
                  onTabChange={onTabChange}
                  translate={translate}
                  locale={locale}
                />
              ) : (
                <GoodsFavorites
                  onTabChange={onTabChange}
                  translate={translate}
                  locale={locale}
                />
              )}
            </TabPanel>
          </div>
        </TabContext>
      </div>
    </div>
  )
}
