import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'

import { favoritesApi } from '../favorites.api'
import { components } from '@plandi/common/src/generated'
import { removeEmptyFieldObject, useLocale } from '@plandi/common'
import { useFavoritesFilters } from '../favorites.param'
import { SPECIALIST_FAVORITE_TENDERS } from '../favorites.constants'

export const useQuerySpecialistsAdvertsFavorites = (
  options?: UseQueryOptions<
    components['schemas']['FavoritesSpecialistTenderListResource'],
    AxiosError,
    components['schemas']['FavoritesSpecialistTenderListResource']
  >
) => {
  const { page, sort } = useFavoritesFilters()
  const params = removeEmptyFieldObject({ sort })
  const locale = useLocale()

  const normalizedParams = {
    page: page,
    filter: {
      ...params,
      sort: params.sort || 'new'
    }
  }

  return useQuery(
    [
      SPECIALIST_FAVORITE_TENDERS,
      normalizedParams.page,
      normalizedParams.filter.sort,
      locale
    ],
    () => favoritesApi.getFavoriteSpecialistsTenders(normalizedParams, locale),
    options
  )
}
