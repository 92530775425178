import { useCallback } from 'react'
import {
  SORTING_NEW,
  SORTING_POPULAR_FAVORITE,
  SORTING_USER_STATISTIC
} from '@plandi/common'
import {
  createEnumParam,
  NumberParam,
  useQueryParams,
  withDefault
} from 'use-query-params'

export const sortFavoritesParam = createEnumParam([
  SORTING_NEW,
  SORTING_POPULAR_FAVORITE,
  SORTING_USER_STATISTIC
])

export const favoritesFiltersQueryParamConfigMap = {
  sort: withDefault(sortFavoritesParam, undefined, true),
  page: withDefault(NumberParam, 1, true)
}

export const useFavoritesFilters = () => {
  const [favoritesFilters, setParams] = useQueryParams(
    favoritesFiltersQueryParamConfigMap
  )

  const setFavoritesFilters = useCallback(
    (value: Partial<typeof favoritesFilters>) => {
      setParams((oldValue) => ({ ...oldValue, ...value }))
    },
    [setParams]
  )

  return {
    ...favoritesFilters,
    setFavoritesFilters
  }
}
