import { useCallback } from 'react'
import {
  createEnumParam,
  NumberParam,
  useQueryParams,
  withDefault
} from 'use-query-params'
import { SORTING_CHEAP, SORTING_EXPENSIVE, SORTING_NEW } from '@plandi/common'

export const SortProjectsFavoritesParam = createEnumParam([
  SORTING_NEW,
  SORTING_CHEAP,
  SORTING_EXPENSIVE
])

export const ProjectsFavoritesFiltersQueryParamConfigMap = {
  sort: withDefault(SortProjectsFavoritesParam, undefined, true),
  page: withDefault(NumberParam, 1, true)
}

export const useGoodsFavoritesFilters = () => {
  const [projectsFavoritesFilters, setParams] = useQueryParams(
    ProjectsFavoritesFiltersQueryParamConfigMap
  )

  const setGoodsFavoritesFilters = useCallback(
    (value: Partial<typeof projectsFavoritesFilters>) => {
      setParams((oldValue) => ({ ...oldValue, ...value }))
    },
    [setParams]
  )

  return {
    ...projectsFavoritesFilters,
    setProjectsFavoritesFilters: setGoodsFavoritesFilters
  }
}
