import {
  useQueryProfileCity,
  useQueryProfileCountry,
  useQueryProfileSpecialistSpecializations,
  useQueryProfileCompanyKindOfActivities
} from './queries'

type TagsTender = {
  city?: {
    id?: number
    name?: string
  } | null
  specialization?: {
    id?: number
    name?: string
  }
  country?: {
    id?: number
    name?: string
  }
  activityKind?: {
    id?: number
    name?: string
  }
  activityKindId?: number
  countryId?: number
  specializationId?: number
  isRemoteWork?: boolean
  skills?: string[]
  enabled?: boolean
}

export const useTagsAdvert = (options?: TagsTender) => {
  const { data: activityKind } = useQueryProfileCompanyKindOfActivities({
    select: (data) =>
      data?.find((activityKind) => activityKind.id === options?.activityKindId),
    enabled: options?.enabled
  })

  const { data: country } = useQueryProfileCountry({
    select: (data) =>
      data?.find((country) => country.id === options?.countryId),
    enabled: options?.enabled
  })

  const { data: specialization } = useQueryProfileSpecialistSpecializations({
    select: (data) =>
      data?.find(
        (specialization) => specialization.id === options?.specializationId
      ),
    enabled: options?.enabled
  })

  const { data: city } = useQueryProfileCity({
    select: (data) => data?.find((city) => city.id === options?.city?.name),
    enabled: options?.enabled
  })

  const specializationName =
    options?.specialization?.name || specialization?.name

  const activityKindName = options?.activityKind?.name || activityKind?.name

  const specialist =
    options?.specialization?.name || specialization?.name
      ? ['Специалист', specializationName]
      : []

  const company = activityKindName ? ['Компания', activityKindName] : []

  const isRemoteWork = options?.isRemoteWork ? 'Удаленная работа' : ''

  return [
    country?.name,
    city?.name,
    ...specialist,
    ...company,
    isRemoteWork,
    ...(options?.skills ?? [])
  ].filter((tag): tag is string => Boolean(tag))
}
