import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useEmptyContentStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%'
    }
  })
)
