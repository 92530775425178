import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useBreadcrumbsStyles = makeStyles(() =>
  createStyles({
    Breadcrumbs: {
      marginBottom: '30px',
      ['& ol']: {
        ['& .MuiBreadcrumbs-separator']: {
          margin: '5px 0px 0px 0px'
        }
      }
    },
    homeIcon: {
      width: '14px',
      height: '13.5px',
      marginRight: '2px'
    },
    BreadCrumbs_Homelink: {
      display: 'flex'
    },
    BreadCrumbs_links: {
      fontSize: '12px',
      marginLeft: '3.5px',
      marginRight: '3.5px',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      lineHeight: '15px',
      letterSpacing: '0em',
      textDecoration: 'none',
      color: '#00000080'
    }
  })
)
