import { makeStyles, createStyles } from '@material-ui/core'

export const useProjectFavoritesStyles = makeStyles((theme) =>
  createStyles({
    title: {
      display: 'flex',
      marginRight: theme.spacing(2)
    },

    head: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 48
      },

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        rowGap: 30,
        padding: 0
      }
    },

    titlegroup: {
      display: 'flex',
      alignItems: 'baseline',
      marginRight: theme.spacing(5),
      flexShrink: 0,

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginRight: 0,
        marginBottom: theme.spacing(3)
      }
    },

    sort: {
      width: '100%',
      marginBottom: 25,
      paddingTop: 0,
      [theme.breakpoints.down('sm')]: {
        width: 245,
        marginBottom: 0
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },

    tabList: {
      '& .MuiTabs-flexContainer': {
        gap: 18
      }
    },

    tab: {
      '&.MuiTab-root': {
        [theme.breakpoints.down('sm')]: {
          minWidth: 50,
          padding: 0
        }
      }
    },

    paginationBlock: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(8)
    }
  })
)
