import Button from '@material-ui/core/Button'
import { useRouter } from 'next/router'
import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useMediaQuery, useTheme } from '@material-ui/core'

type BackButtonProps = {
  className?: string
  handleClick?: () => void
  title: string
}

export const BackButton: React.FC<BackButtonProps> = ({
  className,
  handleClick,
  title
}) => {
  const router = useRouter()

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down(960))

  return (
    <div className={className}>
      {isTablet ? (
        <Button
          style={{ marginBottom: 20 }}
          startIcon={<ArrowBackIcon />}
          onClick={handleClick ? handleClick : () => router.back()}
          size="small"
        >
          {title}
        </Button>
      ) : (
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleClick ? handleClick : () => router.back()}
          size="small"
        >
          {title}
        </Button>
      )}
    </div>
  )
}
