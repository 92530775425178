import { useCallback } from 'react'
import {
  createEnumParam,
  NumberParam,
  useQueryParams,
  withDefault
} from 'use-query-params'
import { SORTING_POPULAR_FAVORITE, SORTING_DATE_FAVORIT } from '@plandi/common'

export const SortProjectsFavoritesParam = createEnumParam([
  SORTING_DATE_FAVORIT,

  SORTING_POPULAR_FAVORITE
])

export const ProjectsFavoritesFiltersQueryParamConfigMap = {
  sort: withDefault(SortProjectsFavoritesParam, undefined, true),
  page: withDefault(NumberParam, 1, true)
}

export const useProjectsFavoritesFilters = () => {
  const [projectsFavoritesFilters, setParams] = useQueryParams(
    ProjectsFavoritesFiltersQueryParamConfigMap
  )

  const setProjectsFavoritesFilters = useCallback(
    (value: Partial<typeof projectsFavoritesFilters>) => {
      setParams((oldValue) => ({ ...oldValue, ...value }))
    },
    [setParams]
  )

  return {
    ...projectsFavoritesFilters,
    setProjectsFavoritesFilters
  }
}
