import { AxiosError } from 'axios'
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'

import { useAppDispatch } from '../../../../store'
import {
  pushDangerNotification,
  pushSuccessNotification,
  useLocale
} from '@plandi/common'
import { favoritesApi } from '../favorites.api'
import { useFavoritesFilters } from '../favorites.param'
import { ADVERTS_FAVORITE_TENDERS } from '../favorites.constants'

export const useMutationDeleteFavoriteTender = (
  options?: UseMutationOptions<
    unknown,
    AxiosError,
    { advertId: number; type?: 'specialist' | 'company' }
  >
) => {
  const locale = useLocale()
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const failMessage =
    locale === 'ru'
      ? 'Произошла ошибка при попытке удалить заказ из избранного'
      : 'An error occurred when trying to remove an order from favorites'

  const { sort, page } = useFavoritesFilters()

  return useMutation(
    ({ advertId }) =>
      favoritesApi.deleteFavoriteSpecialistTender(advertId, locale),
    {
      ...options,
      onSuccess(data, variables, context) {
        options?.onSuccess?.(data, variables, context)
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? 'Заказ успешно удалён из избранного'
              : 'The order was successfully removed from favorites'
          )
        )
        queryClient.invalidateQueries([
          ADVERTS_FAVORITE_TENDERS,
          page,
          sort || 'new',
          locale
        ])
      },
      onError(error, variables, context) {
        options?.onError?.(error, variables, context)
        dispatch(pushDangerNotification(failMessage))
      }
    }
  )
}
