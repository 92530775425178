import { createStyles, makeStyles } from '@material-ui/core'

export const useFavoritesPopupMenuStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiMenu-paper': {
        width: 400,
        padding: '45px 0',
        [theme.breakpoints.down('xs')]: {
          width: 200,
          padding: '0'
        }
      }
    },

    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      color: '#000'
    },

    menuItemText: {
      fontWeight: 500,
      color: '#000'
    },

    activeItemText: {
      color: '#E52428'
    }
  })
)
