/* eslint-disable max-len */
import { TranslationDictionary } from '@plandi/common'
import { operations } from '@plandi/common/src/generated'

export type QueryParamsProjectsFavorites =
  operations['AppHttpControllersApiV1MarketProjectsProjectFavoriteController::getMe']['parameters']['query']

export type FAVORITES_TYPE = 'adverts' | 'shop'

export enum FAVORITES_TYPES {
  ADVERTS = 'adverts',
  SHOP = 'shop'
}

export const getFavoritesTypesValue = (translate: {
  job: { title2: any }
  market: { title: any }
}) => {
  return {
    [FAVORITES_TYPES.ADVERTS]: translate?.job?.title2,
    [FAVORITES_TYPES.SHOP]: translate?.market.title
  }
}

export const FAVORITES_TYPES_VALUES = {
  [FAVORITES_TYPES.ADVERTS]: 'Фриланс и Работа',
  [FAVORITES_TYPES.SHOP]: 'Проектный маркет'
}

export const FAVORITES_TYPES_ARR = Object.entries(FAVORITES_TYPES_VALUES)

export const getFavoritesTypesArr = (
  translate?: TranslationDictionary['data']
): any => Object.entries(getFavoritesTypesValue(translate))

export type QueryParamsFavoritesAdverts = {
  filter: {
    sort?: string
  }
  page?: number
}
