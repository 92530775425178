import { AxiosError } from 'axios'
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query'
import { pushSuccessNotification, useLocale } from '@plandi/common'

import { useThunkDispatch } from 'src/store'
import { favoritesApi } from '../favorites.api'

export const useMutationDeleteFavoriteProduct = (
  options?: UseMutationOptions<unknown, AxiosError, number>
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()

  const mutation = useMutation(
    (productId) => favoritesApi.deleteFavoriteProduct(productId, locale),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? 'Товар успешно удален из избранного'
              : 'Product successfully removed from favorites'
          )
        )

        queryClient.invalidateQueries(['favoriteGoods', locale])

        options?.onSuccess?.(data, variables, context)
      }
    }
  )

  return mutation
}
