import { components } from '@plandi/common/src/generated'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'
import { MyProfileSpecialist } from '../../../profile/common'
import { favoritesApi } from '../favorites.api'
import { useLocale } from '@plandi/common'

export const useQueryMyProfileCompany = <
  T = components['schemas']['ProfileCompanyMeResource'] | undefined
>(
  options?: UseQueryOptions<
    components['schemas']['ProfileCompanyMeResource'] | undefined,
    AxiosError,
    T
  >
) => {
  const locale = useLocale()
  const data = useQuery(
    ['MY_PROFILE_COMPANY_KEY', locale],
    () => favoritesApi.getMyProfileCompany(locale),
    options
  )

  return data
}

export const useQueryMyProfileSpecialist = <
  T = MyProfileSpecialist | undefined
>(
  options?: UseQueryOptions<MyProfileSpecialist | undefined, AxiosError, T>
) => {
  const locale = useLocale()
  const data = useQuery(
    ['MY_PROFILE_SPECIALIST_KEY', locale],
    () => favoritesApi.getMyProfileSpecialist(locale),
    options
  )

  return data
}

export const useQueryProfileCity = <
  T = components['schemas']['CityResource'][] | undefined
>(
  options?: UseQueryOptions<
    components['schemas']['CityResource'][] | undefined,
    AxiosError,
    T
  >
) => {
  const locale = useLocale()
  const data = useQuery(
    ['PROFILE_CITY_KEY', locale],
    () => favoritesApi.getProfileCities(locale),
    options
  )
  return data
}

export const useQueryProfileCountry = <
  T = components['schemas']['CountryResource'][] | undefined
>(
  options?: UseQueryOptions<
    components['schemas']['CountryResource'][] | undefined,
    AxiosError,
    T
  >
) => {
  const locale = useLocale()
  const data = useQuery(
    [, locale],
    () => favoritesApi.getProfileCountries(locale),
    options
  )

  return data
}

export const useQueryProfileCompanyKindOfActivities = <
  T = components['schemas']['ActivityKindResource'][] | undefined
>(
  options?: UseQueryOptions<
    components['schemas']['ActivityKindResource'][] | undefined,
    AxiosError,
    T
  >
) => {
  const locale = useLocale()
  const data = useQuery(
    ['PROFILE_COMPANY_ACTIVITY_KINDS_KEY', locale],
    () => favoritesApi.getProfileCompanyActivityKinds(locale),
    options
  )

  return data
}

export const useQueryProfileSpecialistSpecializations = <
  T = components['schemas']['SpecializationResource'][] | undefined
>(
  options?: UseQueryOptions<
    components['schemas']['SpecializationResource'][] | undefined,
    AxiosError,
    T
  >
) => {
  const locale = useLocale()
  const data = useQuery(
    ['PROFILE_SPECIALIST_SPECIALIZATIONS', locale],
    () => favoritesApi.getProfileSpecialistSpecializations(locale),
    options
  )

  return data
}
