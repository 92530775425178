import { getApiClient } from '@plandi/common'
import { components } from '@plandi/common/src/generated'
import {
  QueryParamsFavoritesAdverts,
  QueryParamsProjectsFavorites
} from './types'
import { MyProfileSpecialist } from '../../profile/common'

export const favoritesApi = {
  deleteProject: (projectId: number, locale: string) =>
    getApiClient(locale)
      .delete(`v1/market/projects/${projectId}`)
      .then(({ data }) => data),

  draftProject: (projectId: number, locale: string) =>
    getApiClient(locale)
      .put(`v1/market/projects/${projectId}/draft`)
      .then(({ data }) => data),

  publishProject: (projectId: number, locale: string) =>
    getApiClient(locale)
      .put(`v1/market/projects/${projectId}/published`)
      .then(({ data }) => data),

  getModelsFavorites: (locale: string, params?: QueryParamsProjectsFavorites) =>
    getApiClient(locale)
      .post<{ data: components['schemas']['ModelFavoriteItems'] }>(
        'v1/market/models/favorite/list',
        params
      )
      .then(({ data }) => data.data),

  getProjectsFavorites: (
    locale: string,
    params?: QueryParamsProjectsFavorites
  ) =>
    getApiClient(locale)
      .post<{ data: components['schemas']['ProjectMarketGetResourceItems'] }>(
        'v1/market/projects/favorites',
        params
      )
      .then(({ data }) => data.data),

  deleteFavoriteModel: (modelId: number, locale: string) =>
    getApiClient(locale)
      .get(`v1/market/models/${modelId}/favorite/delete`)
      .then(({ data }) => data),

  addFavoriteModel: (modelId: number, locale: string) =>
    getApiClient(locale)
      .get(`v1/market/models/${modelId}/favorite`)
      .then(({ data }) => data),

  addFavoriteProject: (projectId: number, locale: string) =>
    getApiClient(locale)
      .post(`v1/market/projects/${projectId}/favorite`)
      .then(({ data }) => data),

  deleteFavorite: (projectId: number, locale: string) =>
    getApiClient(locale)
      .delete(`v1/market/projects/${projectId}/favorite`)
      .then(({ data }) => data),

  getFavoriteAdvertsTenders: (
    params: QueryParamsFavoritesAdverts,
    locale: string
  ) =>
    getApiClient(locale)
      .post<{
        data: components['schemas']['FavoritesAdvertsTenderListResource']
      }>('v1/tenders/groups/favorite/list', params)
      .then(({ data }) => data.data),

  getFavoriteSpecialistsTenders: (
    params: QueryParamsFavoritesAdverts,
    locale: string
  ) =>
    getApiClient(locale)
      .post<{
        data: components['schemas']['FavoritesSpecialistTenderListResource']
      }>('v1/tenders/groups/favorite/list', params)
      .then(({ data }) => data.data),

  getFavoriteCompaniesTenders: (
    params: QueryParamsFavoritesAdverts,
    locale: string
  ) =>
    getApiClient(locale)
      .post<{
        data: components['schemas']['FavoritesCompanyTenderListResourse']
      }>('v1/tenders/groups/favorite/list', params)
      .then(({ data }) => data.data),

  deleteFavoriteSpecialistTender: (tenderId: number, locale: string) =>
    getApiClient(locale)
      .delete(`v1/tenders/groups/${tenderId}/favorite`)
      .then(({ data }) => data),

  addFavoriteSpecialistTender: (tenderId: number, locale: string) =>
    getApiClient(locale)
      .post(`v1/tenders/groups/${tenderId}/favorite`)
      .then(({ data }) => data),

  deleteFavoriteCompanyTender: (tenderId: number, locale: string) =>
    getApiClient(locale)
      .delete(`v1/tenders/groups/${tenderId}/favorite`)
      .then(({ data }) => data),

  addFavoriteCompanyTender: (tenderId: number, locale: string) =>
    getApiClient(locale)
      .post(`v1/tenders/groups/${tenderId}/favorite`)
      .then(({ data }) => data),

  getProfileSpecialistSpecializations: (locale: string) =>
    getApiClient(locale)
      .get<components['schemas']['SpecializationListResource']>(
        'v1/profiles/specializations'
      )
      .then(({ data }) => data?.data),

  getMyProfileCompany: (locale: string) =>
    getApiClient(locale)
      .get<{
        data: components['schemas']['ProfileCompanyMeResource']
      }>('v1/profiles/companies/me')
      .then(({ data }) => data.data),

  getMyProfileSpecialist: (locale: string) =>
    getApiClient(locale)
      .get<{ data: MyProfileSpecialist }>('v1/profiles/specialists/me')
      .then(({ data }) => data.data),

  getProfileCities: (locale: string) =>
    getApiClient(locale)
      .get<components['schemas']['CityListResource']>('v1/profiles/cities')
      .then(({ data }) => data.data),

  getProfileCompanyActivityKinds: (locale: string) =>
    getApiClient(locale)
      .get<components['schemas']['ActivityKindListResource']>(
        'v1/profiles/companies/activity-kinds'
      )
      .then(({ data }) => data.data),

  getProfileCountries: (locale: string) =>
    getApiClient(locale)
      .get<components['schemas']['CountryListResource']>(
        'v1/profiles/countries'
      )
      .then(({ data }) => data.data),

  createFeedbackTender: (
    tenderId: number,
    requestBody: components['schemas']['FeedbackAdvertMessageRequest'],
    locale: string
  ) =>
    getApiClient(locale)
      .post(`v1/tenders/feedbacks/${tenderId}`, requestBody)
      .then(({ data }) => data),
  getFavoriteGoods: (
    locale: string,
    params?: {
      sort?: 'new' | 'cheap' | 'expensive' | 'date' | 'popular'
      page?: number
    }
  ) =>
    getApiClient(locale)
      .post('v1/market/goods/favorites', params)
      .then(({ data }) => data.data),
  addFavoriteProduct: (productId: number, locale: string) =>
    getApiClient(locale)
      .post(`v1/market/goods/${productId}/favorite`)
      .then(({ data }) => data),
  deleteFavoriteProduct: (productId: number, locale: string) =>
    getApiClient(locale)
      .delete(`v1/market/goods/${productId}/favorite`)
      .then(({ data }) => data)
}
