import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { components } from '@plandi/common/src/generated'
import { removeEmptyFieldObject, useLocale } from '@plandi/common'

import { modelKeys } from '../favorites.keys'
import { QueryParamsProjectsFavorites } from '../types'
import { favoritesApi } from '../favorites.api'

export const useQueryModelsFavorites = <
  T = components['schemas']['ModelFavoriteItems'] | undefined
>(
  params?: QueryParamsProjectsFavorites,
  options?: UseQueryOptions<
    components['schemas']['ModelFavoriteItems'] | undefined,
    AxiosError,
    T
  >
) => {
  const { sort, ...paramsOptions } = removeEmptyFieldObject(params ?? {})
  const locale = useLocale()
  const normalizeParams = paramsOptions
  if (sort) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    normalizeParams.filter = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...normalizeParams.filter,
      sort
    }
  }
  return useQuery(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [modelKeys.favorite(normalizeParams), locale],
    () => favoritesApi.getModelsFavorites(locale, normalizeParams),
    options
  )
}
