import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Tooltip from '@material-ui/core/Tooltip'
import StarOutlinedIcon from '@material-ui/icons/StarOutlined'
import clsx from 'clsx'

import { useProductCardStyles } from './product-card.styles'
import {
  useMutationAddFavoriteProduct,
  useMutationDeleteFavoriteProduct
} from '../mutations'
import { useLocale } from '@plandi/common'

type ProductCardFavoriteIconProps = {
  className?: string
  isFavorite?: boolean
  productId?: number
}

export const ProductCardFavoriteIcon: React.FC<ProductCardFavoriteIconProps> = (
  props
) => {
  const classes = useProductCardStyles()
  const locale = useLocale()
  const mutationAddFavorite = useMutationAddFavoriteProduct()
  const mutationDeleteFavorite = useMutationDeleteFavoriteProduct()

  const handleDeleteFavorite = () => {
    if (props.productId) {
      mutationDeleteFavorite.mutate(props.productId)
    }
  }

  const handleAddFavorite = () => {
    if (props.productId) {
      mutationAddFavorite.mutate(props.productId)
    }
  }

  return (
    <div className={clsx(classes.iconWrap, props.className)}>
      {props.isFavorite ? (
        <Tooltip
          title={
            locale === 'ru' ? 'Убрать из ибранного' : 'Remove from favorites'
          }
          onClick={handleDeleteFavorite}
        >
          <IconButton color="default" component="span" size="small">
            <StarOutlinedIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          title={locale === 'ru' ? 'Добавить в избранное' : 'Add to favorites'}
        >
          <IconButton
            color="default"
            component="span"
            size="small"
            onClick={handleAddFavorite}
          >
            <StarBorderIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}
