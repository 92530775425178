import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Skeleton from '@material-ui/lab/Skeleton'

import { useProjectCardStyles } from './project-card.styles'

export const ProjectCardSkeleton = () => {
  const classes = useProjectCardStyles()

  return (
    <Card elevation={0} className={classes.root}>
      <CardContent className={classes.content}>
        <Skeleton variant="rect" className={classes.img} />
        <Skeleton className={classes.projectName} />
        <div className={classes.info}>
          <Skeleton width={156} className={classes.name} height={23} />
          <Skeleton width={100} height={23} />
        </div>
        <div className={classes.actions}>
          <Skeleton width={100} height={23} className={classes.cost} />
        </div>
      </CardContent>
    </Card>
  )
}
