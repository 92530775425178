import * as React from 'react'
import { Tab } from '@material-ui/core'
import { TabList } from '@material-ui/lab'

import { FAVORITES_TYPE } from 'src/features/favorites'

type TabsComponentProps = {
  tabClassName?: string
  tabListClassName?: string
  onTabChange: (value: FAVORITES_TYPE) => void
  tabsValues: string[][]
}

export const TabsComponent: React.FC<TabsComponentProps> = ({
  tabClassName,
  tabListClassName,
  onTabChange,
  tabsValues
}) => {
  return (
    <TabList
      textColor="secondary"
      onChange={(_, value) => onTabChange(value)}
      className={tabListClassName}
    >
      {tabsValues?.map(([key, value]) => (
        <Tab key={key} label={value} value={key} className={tabClassName} />
      ))}
    </TabList>
  )
}
