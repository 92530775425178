import { makeStyles, createStyles } from '@material-ui/core'

export const useProjectAuthorMenuStyles = makeStyles(() =>
  createStyles({
    icon: {
      maxWidth: 'max-content'
    },

    popper: {
      zIndex: 1
    }
  })
)
