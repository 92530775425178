import { createStyles, makeStyles } from '@material-ui/core'

export const useUIStyles = makeStyles(() =>
  createStyles({
    filledRedButton: {
      background: 'linear-gradient(97.4deg, #E52428 10.98%, #C40F13 70.66%)'
    },

    outlinedRedButton: {
      backgroundColor: 'transparent',
      border: '2px solid rgb(161, 45, 49)'
    }
  })
)
