import React, { FC } from 'react'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { Pagination } from '@material-ui/lab'

import {
  sorting,
  SORTING_POPULAR_FAVORITE,
  SORTING_DATE_FAVORIT,
  SERVICES_URL,
  useTranslation
} from '@plandi/common'
import { EmptyContent, TabsComponent } from 'src/common'
import { useProjectFavoritesStyles } from './model-favorites.styles'
import { useProjectsFavoritesFilters } from './model-favorites.param'
import { ProjectFavoritesSkeleton } from './model-favorites-skeleton'
import {
  FAVORITES_TYPE,
  ModelCard,
  useQueryModelsFavorites,
  getFavoritesTypesArr
} from '../common'
import { useRouter } from 'next/router'

type ModelFavoritesProps = {
  onTabChange: (value: FAVORITES_TYPE) => void
}

export const ModelFavorites: FC<ModelFavoritesProps> = ({ onTabChange }) => {
  const theme = useTheme()
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useProjectFavoritesStyles()
  const { data: translate } = useTranslation()
  const { locale } = useRouter()

  const { sort, page, setProjectsFavoritesFilters } =
    useProjectsFavoritesFilters()

  const { data: projectsFavorites, isLoading } = useQueryModelsFavorites({
    sort,
    page
  })

  const handlerMorePage = (
    event: React.ChangeEvent<unknown>,
    pageValue: number
  ) => {
    setProjectsFavoritesFilters({ page: pageValue })
  }

  if (isLoading) {
    return <ProjectFavoritesSkeleton />
  }

  if (!projectsFavorites?.items || !projectsFavorites?.items.length) {
    return (
      <EmptyContent
        message={translate?.['no-exist-models']}
        showButton
        buttonProps={{
          href:
            locale === 'ru'
              ? `${SERVICES_URL.shop}/ru${'/models'}`
              : SERVICES_URL.shop + '/models',
          text: translate?.messages?.button
        }}
      />
    )
  }

  return (
    <>
      <div className={classes.head}>
        {isTabletScreen && (
          <TabsComponent
            tabListClassName={classes.tabList}
            tabClassName={classes.tab}
            onTabChange={onTabChange}
            tabsValues={getFavoritesTypesArr(translate)}
          />
        )}
        <TextField
          select
          value={sort ?? SORTING_DATE_FAVORIT}
          onChange={(event) =>
            setProjectsFavoritesFilters({
              sort: event.target.value as typeof sort
            })
          }
          fullWidth
          className={classes.sort}
        >
          {[SORTING_DATE_FAVORIT, SORTING_POPULAR_FAVORITE].map((key) => (
            <MenuItem key={key} value={key}>
              {sorting(translate)[key]}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <Grid container spacing={2}>
        {projectsFavorites.items.map((modelFavorits) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={modelFavorits.id}>
            <ModelCard slug_name={''} {...modelFavorits} isFavorite={true} />
          </Grid>
        ))}
      </Grid>
      {projectsFavorites?.pageTotal ? (
        <Grid item xs={12}>
          <div className={classes.paginationBlock}>
            <Pagination
              count={projectsFavorites?.pageTotal}
              onChange={handlerMorePage}
              showFirstButton
              showLastButton
              size={isMobileScreen ? 'small' : 'medium'}
              page={page}
            />
          </div>
        </Grid>
      ) : null}
    </>
  )
}
