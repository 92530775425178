import { makeStyles, createStyles } from '@material-ui/core'

export const useAdvertTenderFeedbackModalStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        width: '100%',
        margin: 0
      }
    },

    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    }
  })
)
