import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Tooltip from '@material-ui/core/Tooltip'
import StarOutlinedIcon from '@material-ui/icons/StarOutlined'
import clsx from 'clsx'

import { useProjectCardStyles } from './project-card.styles'
import {
  useMutationAddFavoriteProject,
  useMutationDeleteFavoriteProject
} from '../mutations'

type ProjectCardFavoriteIconProps = {
  className?: string
  isFavorite?: boolean
  projectId?: number
}

export const ProjectCardFavoriteIcon: React.FC<ProjectCardFavoriteIconProps> = (
  props
) => {
  const classes = useProjectCardStyles()

  const mutationAddFavorite = useMutationAddFavoriteProject()
  const mutationDeleteFavorite = useMutationDeleteFavoriteProject()

  const handleDeleteFavorite = () => {
    if (props.projectId) {
      mutationDeleteFavorite.mutate(props.projectId)
    }
  }

  const handleAddFavorite = () => {
    if (props.projectId) {
      mutationAddFavorite.mutate(props.projectId)
    }
  }

  return (
    <div className={clsx(classes.iconWrap, props.className)}>
      {props.isFavorite ? (
        <Tooltip title="Убрать из ибранного" onClick={handleDeleteFavorite}>
          <IconButton color="default" component="span" size="small">
            <StarOutlinedIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Добавить в избранное">
          <IconButton
            color="default"
            component="span"
            size="small"
            onClick={handleAddFavorite}
          >
            <StarBorderIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}
