import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

import {
  Masonry,
  // getServiceURL,
  SERVICES_URL,
  sorting,
  SORTING_CHEAP,
  SORTING_EXPENSIVE,
  SORTING_NEW,
  TranslationDictionary
} from '@plandi/common'
import { EmptyContent, TabsComponent } from 'src/common'
import { useProjectFavoritesStyles } from './project-favorites.styles'
import { useProjectsFavoritesFilters } from './project-favorites.param'
import { ProjectFavoritesSkeleton } from './project-favorites-skeleton'
import {
  FAVORITES_TYPE,
  getFavoritesTypesArr,
  ProjectCard,
  useQueryProjectsFavorites
} from '../common'

type ProjectFavoritesProps = {
  onTabChange: (value: FAVORITES_TYPE) => void
  translate?: TranslationDictionary['data']
  locale: string
}

export const ProjectFavorites: React.FC<ProjectFavoritesProps> = ({
  onTabChange,
  translate,
  locale
}) => {
  const classes = useProjectFavoritesStyles()
  const theme = useTheme()
  const url = SERVICES_URL
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const { sort, page, setProjectsFavoritesFilters } =
    useProjectsFavoritesFilters()

  const { data: projectsFavorites, isLoading } = useQueryProjectsFavorites({
    sort,
    page
  })

  const breakpointColsMasonry = {
    default: 3,
    [theme.breakpoints.values.xl]: 3,
    [theme.breakpoints.values.lg]: 2,
    [theme.breakpoints.values.sm]: 1
  }

  const handlerMorePage = (
    event: React.ChangeEvent<unknown>,
    pageValue: number
  ) => {
    setProjectsFavoritesFilters({ page: pageValue })
  }

  if (isLoading) {
    return <ProjectFavoritesSkeleton />
  }

  return (
    <div>
      {projectsFavorites?.items.length ? (
        <>
          <div className={classes.head}>
            {isTabletScreen && (
              <TabsComponent
                onTabChange={onTabChange}
                tabClassName={classes.tab}
                tabListClassName={classes.tabList}
                tabsValues={getFavoritesTypesArr(translate)}
              />
            )}
            <TextField
              select
              value={sort ?? 'new'}
              onChange={(event) =>
                setProjectsFavoritesFilters({
                  sort: event.target.value as typeof sort
                })
              }
              fullWidth
              className={classes.sort}
            >
              {[SORTING_NEW, SORTING_CHEAP, SORTING_EXPENSIVE].map((key) => (
                <MenuItem key={key} value={key}>
                  {sorting(translate)[key]}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <Masonry breakpointCols={breakpointColsMasonry}>
            {projectsFavorites?.items.map((projectFavorites) => (
              <ProjectCard
                slug_name={''}
                key={projectFavorites.id}
                {...projectFavorites}
                translate={translate}
              />
            ))}
          </Masonry>
          {projectsFavorites?.pageTotal ? (
            <Grid item xs={12}>
              <div className={classes.paginationBlock}>
                <Pagination
                  count={projectsFavorites?.pageTotal}
                  onChange={handlerMorePage}
                  showFirstButton
                  showLastButton
                  size={isMobileScreen ? 'small' : 'medium'}
                  page={page}
                />
              </div>
            </Grid>
          ) : null}
        </>
      ) : (
        <EmptyContent
          message={translate?.['no-favorite-project']}
          showButton
          buttonProps={{
            href:
              locale === 'ru'
                ? `${url.shop}/ru${'/projects'}`
                : url.shop + '/projects',
            text: translate?.favorites.button
          }}
        />
      )}
    </div>
  )
}
