import { makeStyles, createStyles } from '@material-ui/core'

export const useProductCardStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'auto',
      marginBottom: theme.spacing(3),

      '&:hover $menu': {
        visibility: 'visible',
        transition: theme.transitions.create('visibility', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.complex
        })
      }
    },

    info: {
      display: 'flex',
      justifyContent: 'space-between'
    },

    content: {
      padding: theme.spacing(1)
    },

    name: {
      maxWidth: 156
    },

    iconName: {
      [theme.breakpoints.down('xs')]: {
        top: 10,
        left: 0
      }
    },

    img: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      display: 'block',
      minHeight: 112,
      maxHeight: 190,
      marginBottom: theme.spacing(2)
    },

    iconWrap: {
      display: 'flex',
      alignItems: 'center'
    },

    mobileName: {
      display: 'flex',
      // padding: '0 20px',
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },

    projectName: {
      marginBottom: theme.spacing(1),
      minHeight: '3.2em',
      display: 'block'
    },

    fullName: {
      marginLeft: 12,
      marginTop: 2,
      color: 'black',
      minHeight: '2em',
      overflow: 'hidden',
      lineHeight: '40px',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        textOverflow: 'ellipsis'
      }
    },

    actions: {
      alignItems: 'center'
    },

    cost: {
      marginLeft: 'auto'
    },

    menu: {}
  })
)
