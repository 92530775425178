import {
  authUrls,
  useDisclosure,
  useLocale,
  useQueryUser
} from '@plandi/common'
import { useRouter } from 'next/router'

import { URLS } from 'src/router/urls'
import { useQueryMyProfileCompany } from './queries'
import { ADVERT_TENDER_TYPE } from './favorites.constants'
import { AdvertTenderType } from './favorites.types'

export const useFeedbackButton = (type?: AdvertTenderType) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { data: user } = useQueryUser()
  const router = useRouter()
  const locale = useLocale()

  const isCompany = type === ADVERT_TENDER_TYPE.COMPANY

  const { data: profileCompany } = useQueryMyProfileCompany({
    enabled: isCompany && !!user
  })

  const onClick = () => {
    if (!user) {
      location.href = authUrls(locale).signIn

      return
    }

    if (isCompany && !profileCompany) {
      router.push(URLS.profileCompany.create)

      return
    }

    onOpen()
  }

  return {
    isOpen,
    onOpen: onClick,
    onClose
  }
}
