import { components } from '@plandi/common/src/generated'
import { AxiosError } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'
import { useFavoritesFilters } from '../favorites.param'
import { removeEmptyFieldObject, useLocale } from '@plandi/common'
import { favoritesApi } from '../favorites.api'

export const useQueryGoodsFavorites = (
  options?: UseQueryOptions<
    components['schemas']['ProductFavoriteListResource']['data'],
    AxiosError,
    components['schemas']['ProductFavoriteListResource']['data']
  >
) => {
  const { page, sort } = useFavoritesFilters()
  const params = removeEmptyFieldObject({ sort, page })
  const locale = useLocale()

  return useQuery(
    ['favoriteGoods', locale],
    () => favoritesApi.getFavoriteGoods(locale, params),
    options
  )
}
