import React, { FC } from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import { useFavoritesSidebarStyles } from './favorites-sidebar.styles'
import { ADVERT_TENDER_TYPE } from '../favorites.constants'
import { useTranslation } from '@plandi/common'

type Props = {
  advertsCount?: number
  handleChangeFavoritesSubtype: (type: string) => void
}

export const AdvertsFavoritesSidebar: FC<Props> = ({
  advertsCount,
  handleChangeFavoritesSubtype
}) => {
  const classes = useFavoritesSidebarStyles()
  const router = useRouter()

  const { data: translation } = useTranslation()

  return (
    <div className={classes.root}>
      <button
        onClick={() => handleChangeFavoritesSubtype(ADVERT_TENDER_TYPE.ADVERTS)}
        className={clsx(classes.sidebarBtn, {
          [classes.activeBtn]: router.query.type !== ADVERT_TENDER_TYPE.COMPANY
        })}
      >
        <span className={classes.btnText}>
          {translation?.['total-tenders']}
        </span>
        <span>{advertsCount || 0}</span>
      </button>
    </div>
  )
}
